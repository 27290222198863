






















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { mapState } from "vuex";
import { Prop } from "vue-property-decorator";
import CloudArrowDownIcon from '@/assets/icons/heroicons/CloudArrowDownIcon.vue';
import Utils from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';

export interface ReportCardDto { 
  name: string, 
  source: 'Dynamic' | 'Static', 
  type: 'General' | 'Individual', 
  description: string,
  filePath?: string,
}

@Component({
  components: { SygniRoundedButton, SygniCard, CloudArrowDownIcon },
  computed: {
    ...mapState('genprox', {
      packages: (state: any) => state.packages,
      activeUserContext: (state: any) => state.activeUserData.context
    })
  }
})
export default class ReportCard extends Vue {
  @Prop({ default: null }) report: ReportCardDto

  handlePreview() {
    const emitAction = this.report?.source === 'Dynamic' ? 'loadDynamicReport' : 'loadStaticReport'
    this.$emit(emitAction, this.report)
  }

  handleDownload() {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${this.report?.filePath}`, this.report?.name || '')
  }
}

var render = function () {
  var _vm$report, _vm$report2, _vm$report3, _vm$report4, _vm$report5, _vm$report6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['report-card', ((_vm$report = _vm.report) === null || _vm$report === void 0 ? void 0 : _vm$report.type) === 'Individual' ? 'report-card--individual' : ''],
    on: {
      "click": _vm.handlePreview
    }
  }, [_c('div', {
    staticClass: "report-card__inner"
  }, [_c('div', {
    staticClass: "report-card__info"
  }, [_c('div', {
    staticClass: "report-card__info-flex"
  }, [_c('p', {
    staticClass: "report-card__description"
  }, [_vm._v(_vm._s((_vm$report2 = _vm.report) === null || _vm$report2 === void 0 ? void 0 : _vm$report2.source))]), _c('div', {
    staticClass: "report-card__prefix"
  }, [_vm._v(_vm._s((_vm$report3 = _vm.report) === null || _vm$report3 === void 0 ? void 0 : _vm$report3.type))])]), _c('p', {
    staticClass: "report-card__name"
  }, [_vm._v(_vm._s((_vm$report4 = _vm.report) === null || _vm$report4 === void 0 ? void 0 : _vm$report4.name))]), _c('p', {
    staticClass: "report-card__description",
    domProps: {
      "innerHTML": _vm._s((_vm$report5 = _vm.report) === null || _vm$report5 === void 0 ? void 0 : _vm$report5.description)
    }
  })]), _c('div', {
    staticClass: "report-card__footer"
  }, [_c('div', {
    staticClass: "report-card__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-primary"
  }, [_vm._v("Show report")]), ((_vm$report6 = _vm.report) === null || _vm$report6 === void 0 ? void 0 : _vm$report6.source) === 'Static' ? _c('sygni-rounded-button', {
    staticClass: "filled gn-primary",
    nativeOn: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleDownload.apply(null, arguments);
      }
    }
  }, [_c('CloudArrowDownIcon')], 1) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-select', {
    staticClass: "sygni-context-picker",
    attrs: {
      "legacyStyling": _vm.legacyStyling,
      "options": _vm.options,
      "label": _vm.label,
      "value": _vm.viewedId
    },
    on: {
      "input": _vm.setViewedId
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }